import axios from "axios";

$(document).on("click", ".celebrityActiveToggle", function() {
  if (confirm('Are you sure ?')) {
    toggle_status($(this).data('id'), '/celebrities/toggle_active');
  } else {
    e.preventDefault();
    return false;
  }
});

function toggle_status(id, url) {
  $.ajax({
    url: url,
    type: 'PUT',
    data: {id: id}
  });
}

$(document).on("click", ".creating_new_admin", function(){
    var name = document.getElementById('name_id').value
    var address = document.getElementById('minter_address').value
    var celebrity_slug = document.getElementById('celebrity_hashid').value
    if(name!='' && address!=''){
      window.addAdminForCelebrity(name, address, celebrity_slug)
    }else{
      toastr.error('Enter a valid name and address!')
    }
})

$(document).on("click", ".remove_admin", function(){
    var admin_id = $(this).attr('data-id')
    var admin_address = $(this).attr('data-address')
    var celebrity_slug = document.getElementById('celebrity_hashid').value
    window.removeCelebrityAdmin(admin_id, admin_address, celebrity_slug)
})

$(document).on('click', '.not_admin', function(){
  toastr.error('Connect with admin wallet!')
})


window.celebrityAdminAdded = function celebrityAdminAdded(slug){
  toastr.success('Admin successfully added!')
  window.location.href = "/celebrities/" + slug + '/admins'
}

window.celebrityAdminRemoved = function celebrityAdminRemoved(slug){
  toastr.success('Admin successfully removed!')
  window.location.href = "/celebrities/" + slug + '/admins'
}

$(document).on("change", "#file-1", function(e) {
  var file = e.currentTarget.files[0];
  var imagePreview = $('#imagePreviewRes');
  var closePreviewBtn = $('#close-preview-button1');
  var placeholder = $('#placeholder');
  var fileId = $('#file-1');
  var chooseFile = $('#choose_file_selected');
  readURLSingle(this, file, imagePreview, closePreviewBtn, placeholder, fileId, chooseFile, 'file-1', true);
});

function readURLSingle(input, file, imagePreview, closePreviewBtn, placeholder, fileId, chooseFile, fileName, coverImg) {
  var ftype = file.type;
  var fsize = file.size / 1024 / 1024; // in MBs
  if (fsize > 30) {
    return toastr.error('Invalid file size!. Must be less than 30MB');
  }
  var imgExt = ['image/png', 'image/jpeg', 'image/gif', 'image/webp'];
  var audExt = ['audio/mp3', 'audio/webm', 'audio/mpeg'];
  var vidExt = ['video/mp4', 'video/webm'];
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function(e) {
      if (imgExt.includes(ftype)) {
        imagePreview.css('background-image', 'url('+e.target.result +')');
        imagePreview.css({ 'height': '225px' });
      } else if (coverImg) {
        return toastr.error('Invalid file type!');
      } else if (audExt.includes(ftype)) {
        if (fileName == 'file-2') {
          $('.coverUpload').removeClass("hide");
          $('#file-3').prop('required', true);
        }
        var customID = "audio_here_"+fileName;
        imagePreview.html('<audio width="300" height="300" controls><source src="mov_bbb.mp4" id='+customID+'> </audio>');
        imagePreview.css({ 'height': '55px' });
        $('#'+customID)[0].src = URL.createObjectURL(input.files[0]);
        $('#'+customID).parent()[0].load();
      } else if (vidExt.includes(ftype)) {
        if (fileName == 'file-2') {
          $('.coverUpload').removeClass("hide");
          $('#file-3').prop('required', true);
        }
        var customID = "video_here_"+fileName;
        imagePreview.html('<video width="300" height="200" controls><source src="mov_bbb.mp4" id='+customID+'> </video>');
        imagePreview.css({ 'height': '225px' });
        $('#'+customID)[0].src = URL.createObjectURL(input.files[0]);
        $('#'+customID).parent()[0].load();
      } else {
        return toastr.error('Invalid file type!');
      }
      imagePreview.css({
        'width': '300px',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center',
        'margin-left': 'auto',
        'margin-right': 'auto',
        'border-radius': '15px'
      });
      closePreviewBtn.css('display', 'inline-block');
      placeholder.fadeOut(100);
      fileId.fadeOut(100);
      chooseFile.fadeOut(100);
      imagePreview.hide();
      imagePreview.fadeIn(650);
    }

    reader.readAsDataURL(input.files[0]);
  }
}

function closePreview(imagePreview, closePreviewBtn, placeholder, fileId, chooseFile) {
  fileId.val('');
  placeholder.fadeIn(100);
  fileId.fadeIn(100);
  chooseFile.fadeIn(100);
  chooseFile.html('Choose file');
  imagePreview.css({
    'width': 'auto',
    'height': 'auto',
    'background-size': 'cover',
    'background-repeat': 'no-repeat',
    'background-position': 'center',
    'margin-left': 'auto',
    'margin-right': 'auto'
  });
  closePreviewBtn.css('display', 'none');
  imagePreview.css('background-image', 'none');
  imagePreview.html('');
}

$(document).on("click", "#close-preview-button1", function() {
  var imagePreview = $('#imagePreviewRes');
  var closePreviewBtn = $('#close-preview-button1');
  var placeholder = $('#placeholder');
  var fileId = $('#file-1');
  var chooseFile = $('#choose_file_selected');
  closePreview(imagePreview, closePreviewBtn, placeholder, fileId, chooseFile);
});