//import $ from 'jquery'
//import 'select2/dist/css/select2.min.css'
import 'select2'

window.addEventListener('turbolinks:load', () => {
  $('#select_email, #select_seller_slug, #select_buyer_slugs').select2({
    placeholder: 'Enter the Email',
    minimumInputLength: 5,
    ajax: {
      url: '/reports/email_filter',
      dataType: 'json',
      type: "GET",
      quietMillis: 50,
      data: function (params) {
        var query = {
          search: params.term,
          size: 10
        }
        return query;
      },

      processResults: function (data) {
        return {
          results: $.map(data, function (value, id) {
            if ($.inArray(id, $('#select_email, #select_seller_slug, #select_buyer_slugs').val()) === -1) {
              return {
                text: value,
                id: id
              }
            }
          })
        };
      }
    }
  });

  $('#select_tournamant').select2({
    placeholder: 'Tournament Name',
    minimumInputLength: 2,
    ajax: {
      url: '/reports/tournament_filter',
      dataType: 'json',
      type: "GET",
      quietMillis: 50,
      data: function (params) {
        var query = {
          search: params.term,
          size: 10
        }
        return query;
      },

      processResults: function (data) {
        return {
          results: $.map(data, function (value, id) {
            if ($.inArray(id, $('#select_tournamant').val()) === -1) {
              return {
                text: value,
                id: id
              }
            }
          })
        };
      }
    }
  });

  $('#subscribe_email').select2({
    placeholder: 'Enter the Email',
    minimumInputLength: 2,
    ajax: {
      url: 'subscribe_emails/search_filter',
      dataType: 'json',
      type: "GET",
      quietMillis: 50,
      data: function (params) {
        var query = {
          search: params.term,
          colums: 'email',
          size: 10
        }
        return query;
      },

      processResults: function (data) {
        return {
          results: $.map(data, function (value, id) {
            if ($.inArray(id, $('#subscribe_email').val()) === -1) {
              return {
                text: value,
                id: value
              }
            }
          })
        };
      }
    }
  });

  $('#subscribe_fsz').select2({
    placeholder: 'Enter fsz',
    minimumInputLength: 2,
    ajax: {
      url: 'subscribe_emails/search_filter',
      dataType: 'json',
      type: "GET",
      quietMillis: 50,
      data: function (params) {
        var query = {
          search: params.term,
          colums: 'fsz',
          size: 10
        }
        return query;
      },

      processResults: function (data) {
        return {
          results: $.map(data, function (value, id) {
            if ($.inArray(id, $('#subscribe_fsz').val()) === -1) {
              return {
                text: value,
                id: value
              }
            }
          })
        };
      }
    }
  });

});



