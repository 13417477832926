import { ethers} from "ethers";
import axios from "axios";

const chainId = 80001 // 80001 | 137
const chainIdInHex = '0x13881' // In case of testnet use 0x13881 | 0x89
const chainName = 'Mumbai Testnet' // Mumbai Testnet | Polygon Mainnet
const rpcURL = ['https://rpc-mumbai.maticvigil.com/'] //https://rpc-mumbai.maticvigil.com/ | https://polygon-rpc.com/
const blockExplorerUrls = ['https://mumbai.polygonscan.com/'] // https://mumbai.polygonscan.com/ | https://polygonscan.com/

window.isCryptoWalletConnected= async function isCryptoWalletConnected(){
    if($('#current_metamask_address').length!=0){
      var old_account = document.getElementById('current_metamask_address').innerHTML
      try{
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        const signer = provider.getSigner();
        var account = await signer.getAddress();
        if(account != null){
            $('.connect_to_wallet').hide()
            if(old_account!=account){
                document.getElementById('current_metamask_address').innerHTML = account
                window.refreshAccounts();
            }
        }
      }catch(err){
          if(old_account!=null && old_account!=''){
              window.refreshAccounts();
          }
          $('.connect_to_wallet').show()
          $('.only_valid_minter').hide()
          document.getElementById('current_metamask_address').innerHTML = ''
          document.getElementById('minter_status').innerHTML = ''
      }
    }
}

async function requestConnection() {
    // Will skip if user already connected with atleast one account
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    await provider.send("eth_requestAccounts", [])
}

window.connectToWallet = async function connectToWallet(){
    try{
        await requestConnection()
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        const signer = provider.getSigner();
        var account = await signer.getAddress()
        // console.log("Account:", account);
    }catch(err){
        toastr.error(err.message);
    }
}

$(document).on("click", ".connect_to_wallet", function() {
  if(window.ethereum){
    window.connectToWallet()
  }else{
    toastr.error('Install metamask wallet to connect to wallet')
  }
})

window.isRightNetwork = async function isRightNetwork(){
    try{
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        const network = await provider.getNetwork()
        if(network.chainId!=chainId){
            await window.switchNetwork();
        }
    }catch(err){
        console.error(err.message)
    }
}


window.switchNetwork = async function switchNetwork() {
    try {
        await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainIdInHex }],
        });
    } catch (switchError) {
      console.log(switchError)
      if (switchError.code === 4902) {
          try{
          const data = [{
              chainId: chainIdInHex,
              chainName: chainName,
              nativeCurrency: {
              name: 'MATIC',
              symbol: 'MATIC',
              decimals: 18,
              },
              rpcUrls: rpcURL,
              blockExplorerUrls: blockExplorerUrls,
          }]
          const tx = await ethereum.request({method: 'wallet_addEthereumChain', params:data}).catch()
          } catch (addError) {
            console.log(addError)
          }
      }
    }
}

async function CurrentAccount(){
    try{
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        let accounts = await provider.listAccounts();
        if(accounts.length == 0){return null}
        const signer = provider.getSigner();
        var account = await signer.getAddress();
        return account;
    }catch(err){
        console.error(err.message)
    }
}

async function destroySession(token){
   const config = {
    data: {},
    headers: {
      'X-CSRF-TOKEN': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }
  const resp = axios.post(`/admin_logout`, {params: {authenticity_token: token}}, config)
    .then(response => response)
    .catch(err => console.log("Session Error: ", err))
  return resp
}

window.refreshAccounts = async function refreshAccounts(){
  var account = await CurrentAccount();
  if(account!=null){
      await validSuperAdmin(account);
  }
  var content = account==null ? '' : account
  document.getElementById('current_metamask_address').innerHTML = content
  if(account == null){
      await destroySession($('[name="csrf-token"]')[0].content);
      $('.only_valid_minter').hide()
      document.getElementById('minter_status').innerHTML = content
  }
}

async function validSuperAdmin(address){
    var response = await isValidSuperAdmin(address,$('[name="csrf-token"]')[0].content);
    if(response.is_admin === true){
        $('#current_metamask_address').show()
        $('.only_valid_minter').show()
        document.getElementById('minter_status').innerHTML = 'Admin Permissions'
    }else{
        $('#current_metamask_address').hide()
        $('.only_valid_minter').hide()
        document.getElementById('minter_status').innerHTML = 'No permissions. Switch Accounts using Metamask'
    }
}

async function isValidSuperAdmin(address, token) {
  const config = {
    headers: {
      'X-CSRF-TOKEN': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }
  const resp = await axios.get('/valid_super_admin', {params: {address: address, authenticity_token: token}}, config)
    .then((response) => {
      return response.data
    })
    .catch(err => {
      console.log("Error in fetching session", err)
    })
  return resp;
}

async function getOperatorDetails(slug) {
  const config = {
    headers: {
      'X-CSRF-TOKEN': $('[name="csrf-token"]')[0].content,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }
  const resp = await axios.get(`/celebrities/${slug}/fetch_operator`, {params: {authenticity_token: $('[name="csrf-token"]')[0].content}}, config)
    .then((response) => {
      return response.data
    })
    .catch(err => {
      console.log("Error in fetching contract details", err)
    })
  return resp;
}


window.addAdminForCelebrity =  async function addAdminForCelebrity(name, address, celebrity_slug){
    try{
        var response = await getOperatorDetails(celebrity_slug);
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        const signer = provider.getSigner();
        var operator = new ethers.Contract(response.operator, response.details.abi, signer);
        var gasprice = '10'
        var txn = await operator.addCelebrityAdmin(address)
        await txn.wait()
        await addCelebritiesAdmin(name, address, celebrity_slug)
        return window.celebrityAdminAdded(celebrity_slug);
    }catch(err){
        if(err.code == -32603){
            toastr.error(err['data'].message)
        }else{
            console.error(err['message'])
        }
    }
}

window.removeCelebrityAdmin = async function removeCelebrityAdmin(admin_id, address, celebrity_slug){
    try{
        var response = await getOperatorDetails(celebrity_slug);
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        const signer = provider.getSigner();
        var gasprice = '10'
        var operator = new ethers.Contract(response.operator, response.details.abi, signer);
        var txn = await operator.removeCelebrityAdmin(address)
        await txn.wait()
        await removeExistingAdmin(celebrity_slug, admin_id)
        return window.celebrityAdminRemoved(celebrity_slug);
    }catch(err){
      if(err.code == -32603){
          toastr.error(err['data'].message)
      }else{
          console.error(err['message'])
      }
    }
}

async function removeExistingAdmin(slug, id){
  var token = $('[name="csrf-token"]')[0].content
  const config = {
    data: {},
    headers: {
      'X-CSRF-TOKEN': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }
  const resp = axios.post(`/celebrities/${slug}/remove_admin`, {admin_id: id, params: {authenticity_token: token}},  config)
    .then(response => response)
    .catch(err => console.log("Failed to create new minter ", err))
  return resp
}

async function addCelebritiesAdmin(name, address, slug){
  var token = $('[name="csrf-token"]')[0].content
  const config = {
    data: {},
    headers: {
      'X-CSRF-TOKEN': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }
  const resp = axios.post(`/celebrities/${slug}/create_admin`, {name: name, address: address, params: {authenticity_token: token}},  config)
    .then(response => response)
    .catch(err => console.log("Failed to create new minter ", err))
  return resp
}

if(window.ethereum){
  ethereum.on('accountsChanged', (accounts) => {
      // window.isCryptoWalletConnected()
      setTimeout(function(){ window.location.reload() }, 1000);
  });
}

$( window ).on("load",function() {
  if(window.ethereum){
      // window.isCryptoWalletConnected()
      // window.isRightNetwork()
  }
});

setInterval(function() {
  if(window.ethereum){
    // isCryptoWalletConnected();
  }
}, 500);

if(window.ethereum){
  // ethereum.on('chainChanged', (_chainId) => window.isRightNetwork());
}