import consumer from "./consumer"

consumer.subscriptions.create({ channel: "UserChannel", room: "account"}, {
  connected() {
    console.log('BL/AC1:Connected');
  },

  received(data) {
    console.log(`BL/AC1:Success`);
    console.log(data);
    $(".showUser-balance").html(
    ` <strong>Acc Balance:</strong>
      ${data.balance}
    `);
  }
});
